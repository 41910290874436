import React, {useEffect} from 'react'
import {version} from '../package.json'
import {MuiThemeProvider} from '@material-ui/core'
import StoreContext from './stores/StoreContext'
import RootStore from './stores/RootStore'
import muiTheme from './MuiTheme'
import BitrixLoader from './components/BitrixLoader/BitrixLoader'
import {StaticLoader} from './components/StaticLoader/StaticLoader'
import CheckAuth from './components/CheckAuth/CheckAuth'
import AppContent from './components/AppContent/AppContent'
import SeoHelmet from './components/SeoHelmet/SeoHelmet'

const rootStore = new RootStore()

const App = () => {
    useEffect(() => {
        if (process.env.REACT_APP_STAGE === 'production') {
            console.log = () => {}
        }
    }, [])
  return (
    <>
        <MuiThemeProvider theme={muiTheme}>
            <StoreContext.Provider
                value={{
                    rootStore: rootStore,
                    headerStore: rootStore.headerStore,
                    documentsStore: rootStore.documentsStore,
                    documentUpdStore: rootStore.documentUpdStore,
                    documentUkdStore: rootStore.documentUkdStore,
                    ordersStore: rootStore.ordersStore,
                    orderStore: rootStore.orderStore,
                    returnsStore: rootStore.returnsStore,
                    createReturnStore: rootStore.createReturnStore,
                    returnRequestStore: rootStore.returnRequestStore,
                    cartStore: rootStore.cartStore,
                    profileStore: rootStore.profileStore,
                    catalogStore: rootStore.catalogStore,
                    personalStore: rootStore.personalStore,
                    staticStore: rootStore.staticStore,
                    searchStore: rootStore.searchStore,
                    searchResultsStore: rootStore.searchResultsStore,
                    notificationsStore: rootStore.notificationsStore,
                    priceListsStore: rootStore.priceListsStore,
                    sellerOrdersStore: rootStore.sellerOrdersStore,
                    sellerOrderStore: rootStore.sellerOrderStore,
                    sellerShipmentStore: rootStore.sellerShipmentStore,
                    sellerReturnOrdersStore: rootStore.sellerReturnOrdersStore,
                    sellerReturnOrderStore: rootStore.sellerReturnOrderStore,
                    sellerDocumentsStore: rootStore.sellerDocumentsStore,
                    sellerUploadDocumentStore: rootStore.sellerUploadDocumentStore,
                    sellerReturnsStore: rootStore.sellerReturnsStore,
                    sellerReturnStore: rootStore.sellerReturnStore,
                    statsStore: rootStore.statsStore,
                    checkoutStore: rootStore.checkoutStore
                }}>
                <BitrixLoader/>
                <StaticLoader/>
                <CheckAuth/>
                <AppContent/>
            </StoreContext.Provider>
        </MuiThemeProvider>
        {process.env.REACT_APP_STAGE === 'development' && <p style={{position: 'fixed', bottom: 0, left: 0, zIndex: 999}}>{version}</p>}
    </>
  );
}

export default App;
