import axios from 'axios'

export const initDataOrder = async (bx_code: string) => {
    try {
        const result = await axios.post('/api/order/init', {
            'fields': {
                contract: '',
                delivery: 1,
                payment: 1,
                region: '',
                regionCode: bx_code,
                address: '',
                consignee: {
                    inn: '',
                    company: '',
                    kpp: '',
                    name: '',
                    lastName: '',
                    firstName: '',
                    middleName: '',
                    phone: '',
                },
                signatory: {
                    position: '',
                    baseDocument: '',
                    firstName: '',
                    lastName: '',
                    middleName: ''
                }
            }
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return result.data.data
    } catch (e) {
        console.log(e)
    }
}

export const makeOrder = async (data: any) => {
    try {
        const result = await axios.post('/api/order/make', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return result.data.data
    } catch (e) {
        console.log(e)
    }
}