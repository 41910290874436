import {makeAutoObservable, runInAction, toJS} from 'mobx'
import {generateFormData} from '../common/utils'
import {TRegistrationEntity, TRegistrationIndividual, TRegistrationInvite} from '../types/TRegistration'
import {authorize, deauthorize, fetchUser, registration, updateUser} from '../api/user'
import {setCartProduct} from '../api/basket'
import {format} from 'date-fns'
import {fetchManagers} from '../api'

class PersonalStore {
	bitrixAllLoaded = false
	bitrix_sessid = null
	bitrixScriptsLoading = [] as {loaded: boolean}[]
	// Объект для построения корзины
	cartTable:any = {}
	authorized = false
	validAuth = false
	rememberMe = false
	authErrors = []
	inviteLink = ''
	inviteData:any = null
	contract:any = null
	personal:any = null
	deleteModalOpened = false
	deleteUid = ''
	editModalOpened = false
	editUid = ''
	dataTable = {
		loading: true
	}

	constructor() {
		makeAutoObservable(this)
	}

	get profileData() {
		const data = [
			{
				label: 'Договор',
				value: ''
			},
			{
				label: 'Контактное лицо',
				value: ''
			}
		]

		if (this.contract) {
			let date = this.contract?.['date'] || new Date()
			let contractString = this.contract?.['name'] + ' от ' + format(new Date(date), 'dd.MM.yyyy')

			data[0].value = contractString
		}

		if (this.personal) {
			let contactInfo = {
				name: this.personal?.['name'] || '',
				phone: this.personal?.['phone'] || '',
				email: this.personal?.['email'] || '',
			}
			let contactInfoArray = Object.values(contactInfo).filter(item => item !== '')
			data[1].value = contactInfoArray.join(', ')
		}
		return data
	}

	auth = async (data: any) => {
		const formData = generateFormData(data)
		authorize(formData)
			.then(r => {
				runInAction(() => {
					this.authErrors = r.data.errors

					// FIXME хак для установки печенек
					r.data.data.multi.forEach((item: string) => new Image().src = item)

					// FIXME изменить чтобы запрашивалась в кажом запрсое
					if (this.bitrixAllLoaded) {
						this.bitrix_sessid = window.BX.message('bitrix_sessid')
					}

					this.getUser()

					console.log(toJS(r.data))
				})
			})
			.catch(err => console.log(err))
	}

	getUser = async () => {
		fetchUser()
			.then(r => {
				console.log('user data')
				console.log(toJS(r.data))
				runInAction(() => {
					this.authorized = r.data.data.auth
					this.contract = r.data.data.contract
					this.personal = r.data.data.personal
					this.cartTable = this.formatterCart(r.data.data.basket)
					this.inviteLink = r.data.data.personal.invite
				})
			})
			.catch(err => console.log(err))
	}

	logout = async () => {
		deauthorize()
			.then(r => {
				console.log(toJS(r))
				runInAction(() => {
					this.authorized = false
				})
			})
			.catch(err => console.log(err))
	}

	setRememberMe = (name: string, checked: boolean) => {
		runInAction(() => {
			this.rememberMe = checked
		})
	}

	registration = async (data:TRegistrationIndividual | TRegistrationEntity | TRegistrationInvite) => {
		return await registration(data)
	}

	formatterCart = (cartTable:any) => {
		if (cartTable.rows) {
			cartTable.rows.forEach((item:any) => {
				item.uid = item.id
				delete item.id
			})
		}
		return cartTable
	}

	delete = (uid: number) => {
		if (!uid) {
			return null
		}
		setCartProduct(uid, 0)
			.then((r) => this.cartTable = this.formatterCart(r))
	}

	update = (values: any) => {
		const contragentFromForm = {
			name: values.name,
			inn: values.inn,
			kpp: values.kpp,
			bik: values.bik,
			bank: values.bank,
			ks: values.ks,
			rs: values.rs
		}
		const contragent = {...this.personal?.['contragent'] || {}, ...contragentFromForm}
		updateUser({
			firstName: values.firstName,
			lastName: values.lastName,
			middleName: values.middleName,
			email: values.email,
			phone: values.phone,
			contragent: contragent
		})
			.then(r => {
				console.log(r)
				this.getUser()
			})
			.catch(err => {
				console.log(err)
			})
	}

	getManagers = async () => {
		await runInAction(() => {
			this.dataTable.loading = true
			fetchManagers()
				.then(res => {
					this.dataTable = res.data
					this.dataTable.loading = false
				})
				.catch(err => console.log(err))
		})
	}

	deleteUser = (uid: string) => {
		runInAction(() => {
			this.deleteModalOpened = true
			this.deleteUid = uid
		})
	}

	editUser = (uid: string) => {
		runInAction(() => {
			this.editModalOpened = true
			this.editUid = uid
		})
	}

	toggleManager = (uid: string, active: 'Y' | 'N') => {
		updateUser({
			managerId: uid,
			active: active
		})
			.then(r => {
				this.getManagers()
			})
			.catch(err => {
				console.log(err)
			})
	}

}

export default PersonalStore