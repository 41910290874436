import {makeAutoObservable} from 'mobx'
import {initDataOrder} from '../api/order'

class CheckoutStore {

    initData:any = null
    loading = true

    constructor() {
        makeAutoObservable(this)
    }

    fetchInitData = async (bx_code:string) => {
        this.loading = true
        try {
            this.initData = await initDataOrder(bx_code)
            this.loading = false
        } catch (e) {
            console.log(e)
        }
    }
}

export default CheckoutStore