import React, {useLayoutEffect, useState, useContext} from 'react'
import {StylesContext} from '../../../Header'

import IconArrow from '../../../../Svg/IconArrow'
import IconLock from '../../../../Svg/IconLock'
import IconCalendar from '../../../../Svg/IconCalendar'
import {observer} from 'mobx-react'
import StoreContext from '../../../../../stores/StoreContext'
import {Tooltip} from '@material-ui/core'

const Company = observer(() => {
    const styles = useContext(StylesContext)
    const stores = useContext(StoreContext)

    const headerHeight = 72
    const headerBottomHeight = 72
    const [companyMenuOpen, setCompanyMenuOpen] = useState(false)
    const [companyMenuHeight, setCompanyMenuHeight] = useState(0)

    useLayoutEffect(() => {
        function updateHeight() {
            setCompanyMenuHeight(document.documentElement.scrollHeight - (headerHeight + headerBottomHeight))
        }
        window.addEventListener('resize', updateHeight)
        updateHeight()
        return () => window.removeEventListener('resize', updateHeight)
    }, [])

    return (
        <div className={`${styles.headerBottom__company} ${styles.company} ${companyMenuOpen ? styles.open : ''}`}>
            <div className={styles.company__title}>{stores?.personalStore?.personal?.['contragent']?.['name']}</div>
            <div className={`
                ${styles.company__total} 
                ${parseFloat(stores?.personalStore?.contract?.['balance'] || '0') < 0 ? 'color--red' : 'color--white'}`}
                 onClick={() => setCompanyMenuOpen(!companyMenuOpen)}>
                <div
                    className={`${styles['company__total-text']}`}>
                    {stores?.personalStore?.contract?.['balance']} руб.
                </div>
                <IconArrow className={styles['company__total-icon']}/>
            </div>

            {!stores?.personalStore?.contract?.['active'] &&
                <div className={`${styles.company__status} color--yellow`}>
                    <IconLock className={styles['company__status-icon']}/>
                    <Tooltip title={'Временно заблокировано'}>
                        <div className={styles['company__status-text']}>Временно заблокировано</div>
                    </Tooltip>
                </div>}

            <div className={styles.company__details} style={{height: companyMenuHeight}}>
                <div className={styles['company__details-item']}>
                    <div className={styles['company__details-title']}>Договор</div>
                    <div className={styles.company__select}>
                        &laquo;{stores?.personalStore?.contract?.['name']}&raquo;
                    </div>
                </div>
                <div className={styles['company__details-item']}>
                    <div className={styles['company__details-delay']}>
                        {stores?.personalStore?.contract?.['isCredit'] ?
                            <>
                                <IconCalendar className={styles['company__details-icon']}/>
                                <div className={styles['company__details-text']}>{stores?.personalStore?.contract?.['creditDays']} дней отсрочки</div>
                            </>
                            :
                            <div className={styles['company__details-text']}>Договор предоплаты</div>
                        }
                    </div>
                </div>
                {stores?.personalStore?.contract?.['isCredit'] ?
                    <>
                        <div className={styles['company__details-item']}>
                            <div className={styles['company__details-title']}>Кредит</div>
                            <div className={styles['company__details-text']}>{stores?.personalStore?.contract?.['creditSum']} руб.</div>
                        </div>
                        <div className={styles['company__details-item']}>
                            <div className={styles['company__details-title']}>Остаток</div>
                            <div className={styles['company__details-text']}>
                                {parseFloat(stores?.personalStore?.contract?.['creditSum'] || '0') - parseFloat(stores?.personalStore?.contract?.['balance'] || '0')} руб.
                            </div>
                        </div>
                        <div className={styles['company__details-item']}>
                            <div className={styles['company__details-title']}>Итоговая задолженность</div>
                            <div className={`${styles['company__total-text']} ${parseInt(stores?.personalStore?.contract?.['balance'] || '') < 0 ? 'color--red' : 'color--white'}`}>
                                {stores?.personalStore?.contract?.['balance']} руб.
                            </div>
                        </div>
                    </>
                :
                    <div className={styles['company__details-item']}>
                        <div className={styles['company__details-title']}>Сумма</div>
                        <div className={styles['company__details-text']}>
                            {stores?.personalStore?.contract?.['creditSum']} руб.
                        </div>
                    </div>
                }
            </div>
        </div>
    )
})

export default Company
