import React, {useContext, useState} from 'react'
import {StylesContext} from '../Header'
import {Link} from 'react-router-dom'

import IconLocation from '../../Svg/IconLocation'
import IconExit from '../../Svg/IconExit'
import ChooseLocationModal from '../TopBar/components/Location/modals/ChooseLocationModal/ChooseLocationModal'
import StoreContext from '../../../stores/StoreContext'
import {observer} from 'mobx-react'

// type ListProps = {}

const List = observer(() => {
    const [openLocationModal1, setOpenLocationModal1] = useState(false)
    const stores = useContext(StoreContext)
    const styles = useContext(StylesContext)
    const clientProd = (process.env.REACT_APP_STAGE === 'production') && (process.env.REACT_APP_VERSION === 'client')
    return (
        <ul className={styles.menuMob__list}>
            {!clientProd &&
                <>
                    <li className={styles.menuMob__item}>
                        <Link className={styles.menuMob__link} to={'#'}>Каталоги</Link>
                    </li>
                    <li className={styles.menuMob__item}>
                        <Link className={styles.menuMob__link} to={'#'}>Сопутствующие товары</Link>
                    </li>
                </>
            }
            {stores?.personalStore.authorized &&
                <>
                    <li className={styles.menuMob__item}>
                        <Link className={styles.menuMob__link} to={'#'}>Заказы</Link>
                    </li>
                    {!clientProd &&
                        <>
                            <li className={styles.menuMob__item}>
                                <Link className={styles.menuMob__link} to={'#'}>Возвраты</Link>
                            </li>
                            <li className={styles.menuMob__item}>
                                <Link className={styles.menuMob__link} to={'#'}>Документы</Link>
                            </li>
                        </>
                    }
                </>
            }

            {stores &&
                <li className={styles.menuMob__item} onClick={() => setOpenLocationModal1(true)}>
                    <div className={styles.city}>
                        <Link to={'#'} className={styles.city__link}>
                            <IconLocation className={styles.city__icon}/>
                            <div className={styles.city__text}>{stores.staticStore.geo.value}</div>
                        </Link>
                    </div>
                    <ChooseLocationModal
                        state={openLocationModal1}
                        setState={setOpenLocationModal1}/>
                </li>}
            <li className={styles.menuMob__item}>
                <div className={styles.menuMob__exit}>
                    <IconExit/>
                    <span>Выйти</span>
                </div>
            </li>
        </ul>
    )
})

export default List
