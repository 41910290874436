// Загрузка информацци о другом пользователе
import {
    TRegistrationEntity,
    TRegistrationIndividual,
    TRegistrationInvite,
    TRegistrationInviteEmail, TUpdateUser,
} from '../types/TRegistration'
import {request} from './index'
import axios from 'axios'

//загрузка информации о пользователе
export const fetchOtherUser = (params: { email: string, checkword: string } | { invite: string }) => request('GET', '/api/user/info', params)

// Регистрация
export const registration = (formData: TRegistrationIndividual | TRegistrationEntity | TRegistrationInvite) => request('POST', '/api/user/register', formData)

export const updateUser = (param: TRegistrationInvite | TUpdateUser | { invite: string }) => request('POST', '/api/user/update', param)

//Приглашение по имейлу
export const inviteEmail = (data: TRegistrationInviteEmail) => request('POST', '/api/user/invite', data)

//авторизация
export const authorize = async (formData: FormData) => await axios.post('/api/user/auth', formData)

//деавторизация
export const deauthorize = async () => {
    const result = await axios.get('/api/user/logout')
    // FIXME Илюха, чекни меня, разбери меня
    // if (window.bxSession) {
    //     // eslint-disable-next-line no-undef
    //     window.bxSession.checkImmediately = true
    //     const currentDate = new Date((new Date()).getTime() + 60000)
    //     // eslint-disable-next-line no-undef
    //     window.bxSession.dateInput.setTime(currentDate.valueOf())
    //     // eslint-disable-next-line no-undef
    //     window.bxSession.CheckSession()
    // }
    return result
}
export const forgotPass = (email:string) => request('POST', '/api/user/restore', {email})
export const fetchUser = async () => await axios.get('/api/user/info')
// Сбросить контрольную строку у пользователя
export const dischargeStringUser = (email: string) => request('GET', '/api/user/checkword', {email})