import React, {useContext} from 'react'
import {observer} from 'mobx-react'
import StoreContext from '../../stores/StoreContext'
import Logo from '../Logo/Logo'
import Container from '../../layouts/Container/Container'
import LinkList from './components/LinkList/LinkList'
import style from './Footer.module.scss'
import {getYear} from 'date-fns'

const Footer = observer(() => {
    const stores = useContext(StoreContext)
    return (
        <footer className={style.footer}>
            <Container addStyle={{maxWidth: 1200}}>
                <div className={style.footer__menu}>
                    <Logo className={`${style.footer__logo} ${style.footer__item}`}>
                        <div className={style.footer__copyright}>copyright {getYear(new Date())}</div>
                    </Logo>
                    {stores && <LinkList data={stores.staticStore.footerLinks}/>}
                </div>
            </Container>
        </footer>
    )
})

export default Footer
