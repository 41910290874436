import React, {useContext, useState} from 'react'
import {Link} from 'react-router-dom'
import Container from '../../../layouts/Container/Container'
import Logo from '../../Logo/Logo'
import Burger from './components/Burger'
import {StylesContext} from '../Header'
import StoreContext from '../../../stores/StoreContext'
import Location from './components/Location/Location'
import UserMenu from './components/UserMenu/UserMenu'
import ReturnsMenu from './components/ReturnsMenu/ReturnsMenu'
import {observer} from 'mobx-react'

const TopBar = observer(() => {
    const stores = useContext(StoreContext)
    const styles = useContext(StylesContext)
    const [menuUserShowing, setMenuUserShowing] = useState(false)
    const clientProd = (process.env.REACT_APP_STAGE === 'production') && (process.env.REACT_APP_VERSION === 'client')
    return (
        <header className={styles.header}>
            <Container>
                <div className={styles['header__top--flex']}>
                    <Logo className={styles.header__logo}/>

                    <div className={styles.header__basic}>
                        {!clientProd &&
                            <>
                                <Link to='/catalog/' className={`${styles['header__basic-item']} ${styles.catalogs}`}>
                                    <div className={styles.catalogs__icon}><span/></div>
                                    <div className={styles.catalogs__text}>Каталоги</div>
                                </Link>
                                <Link to={'#'} className={styles['header__basic-item']}>Сопутствующие товары</Link>
                            </>}
                        <Location/>
                    </div>

                    {stores?.personalStore.authorized &&
                        <div className={styles.header__addit}>
                            <Link to='/personal/orders' className={styles['header__addit-item']}>Заказы</Link>
                            {!clientProd &&
                                <>
                                    <ReturnsMenu/>
                                    <Link to='/personal/documents' className={styles['header__addit-item']}>Документы</Link>
                                </>}
                        </div>}

                    <div className={styles.header__interaction} onMouseLeave={(e) => setMenuUserShowing(false)}>
                        <UserMenu
                            menuUserShowing={menuUserShowing}
                            setMenuUserShowing={setMenuUserShowing}/>
                        <Burger/>
                    </div>
                </div>
            </Container>
        </header>
    )
})

export default TopBar
