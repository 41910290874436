import React, {Dispatch, useContext, useState} from 'react'
import {Link} from 'react-router-dom'
import Badge from '../../../../Badge/Badge'
import IconCart from '../../../../Svg/IconCart'
import IconUser from '../../../../Svg/IconUser'
import IconArrow from '../../../../Svg/IconArrow'
import {StylesContext} from '../../../Header'
import {observer} from 'mobx-react'
import StoreContext from '../../../../../stores/StoreContext'
import UserPopover from './UserPopover'

type UserMenuProps = {
	menuUserShowing: boolean
	setMenuUserShowing: Dispatch<boolean>
}

const UserMenu = observer(({menuUserShowing, setMenuUserShowing}: UserMenuProps) => {
	const styles = useContext(StylesContext)
	const stores = useContext(StoreContext)
	
	return (
		<>
			{stores &&
				<>
					{/*<Link to={'#'} className={`${styles['header__interaction-item']} ${styles.chats}`}>*/}
					{/*	<IconChat className={styles.chats__icon}/>*/}
					{/*	<Badge number={1} offset={{left: '50%'}}/>*/}
					{/*</Link>*/}

					<Link to='/cart/' className={`${styles['header__interaction-item']} ${styles.basket}`}>
						<IconCart className={styles.basket__icon} />
						{!!stores?.personalStore.cartTable &&
							<Badge number={stores?.personalStore.cartTable.rows?.length} offset={{left: '50%'}}/>}
					</Link>

					<div
						className={`${styles['header__interaction-item']} ${styles.user}`}
						onMouseOver={(e) => setMenuUserShowing(true)}>
						<Link to={'#'} className={styles.user__link}>
							<IconUser className={styles.user__icon}/>
							{!stores?.personalStore.authorized ?
								<Link to='/auth/' className={styles.user__text}>Войти</Link>
								: <IconArrow className={styles.user__arrow}/>}
						</Link>
						{stores?.personalStore.authorized &&
							<>
								{menuUserShowing && <UserPopover/>}
							</>
						}
					</div>
				</>
			}
		</>
	)
})

export default UserMenu