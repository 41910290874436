import React, {useContext} from 'react'
import {observer} from 'mobx-react'
import Company from './Company'
import List from './List'
import {StylesContext} from '../Header'
import StoreContext from '../../../stores/StoreContext'

// type MobileMenuProps = {}

const MobileMenu = observer(() => {
    const stores = useContext(StoreContext)
    const styles = useContext(StylesContext)
    return (
        <>
            {stores &&
                <div className={`${styles.menuMob} ${stores.headerStore.mobileMenuOpened ? styles.active : ''}`}>
                    {stores?.personalStore.authorized && <Company/>}
                    <List/>
                </div>
            }
        </>

    )
})

export default MobileMenu
