import {makeAutoObservable, runInAction, toJS} from 'mobx'
import {fetchSearchResultsCrosses, fetchSearchResultsOffers, fetchDetailsFilters} from '../api/catalog'
import axios from 'axios'
import TSeo from '../types/TSeo'
import {debounce} from '@material-ui/core'

type SearchResultFiltersType = {
	cnt: number
	delivery_min: number
	delivery_max: number
	stock_min: number
	stock_max: number
	price_min: number
	price_max: number
	brands:
		{
			name: string
			code: string
			cnt: number
		}[]
}

type SearchResultFiltersToSendType = {
	cnt: number
	delivery_min: number
	delivery_max: number
	stock_min: number
	stock_max: number
	price_min: number
	price_max: number
	brands: string[]
}

type OrderItemType = 'price_asc' | 'price_desc' | 'stock_asc' | 'stock_desc' | 'delivery_asc' | 'delivery_desc' | ''

class SearchResultsStore {
	infinite = false
	productsAll = 55
	dataTable = {
		settings: {
			desktop: [],
			mobile: [],
			sort: []
		},
		nav: {
			count: 0,
			page: 0,
			size: 0,
			pages: 0
		},
		rows: [],
		loading: false
	}

	crossesNav = {
		nav: {
			count: 0,
			page: 0,
			size: 0,
			pages: 0
		},
		set: false
	}

	dataInfiniteTable = {
		settings: {
			desktop: [
				"brand",
				"product",
				"count",
				"storage",
				"supply",
				"backDays",
				"price",
				"addToCart"
			],
			mobile: [
				"brand",
				"product",
				"count",
				"storage",
				"supply",
				"backDays",
				"price",
				"addToCartMobile"
			],
			sort: [
				"count",
				"supply",
				"price"
			]
		},
		nav: {
			count: 0,
			page: 0,
			size: 0,
			pages: 0
		},
		rows: [] as any,
		loading: false
	}
	filters: SearchResultFiltersType = {
		cnt: 0,
		delivery_min: 0,
		delivery_max: 0,
		stock_min: 0,
		stock_max: 0,
		price_min: 0,
		price_max: 0,
		brands: [
			{
				name: '',
				code: '',
				cnt: 0
			}
		]
	}
	filtersToSend: SearchResultFiltersToSendType = {
		cnt: 0,
		delivery_min: 0,
		delivery_max: 0,
		stock_min: 0,
		stock_max: 0,
		price_min: 0,
		price_max: 0,
		brands: []
	}
	filterSet = false
	filterOpen = false
	article?: string = ''
	filter: string = ''
	order: OrderItemType[] = ['price_asc']
	mod: any = null
	hasNextPage = true
	captionSet = false
	detailsCount = 0
	crossesCount = 0
	loadedOneTime = false
	filterUpdatedOneTime = false
	notFound = false
	endpoint = 'detail'
	currentCrossPage = 0
	filtersAllSet = false
	seo: TSeo = {
		title: '',
		description: '',
		keywords: ''
	}
	personalStore: any

	constructor(rootStore: any) {
		makeAutoObservable(this)
		this.personalStore = rootStore.personalStore
	}

	get foundDetails() {
		return this.dataInfiniteTable.loading ? 'подсчёт...'
			:
			this.captionSet ?
				this.dataInfiniteTable.rows.length - 1 :
				this.dataInfiniteTable.rows.length
	}

	resetTable = () => {
		console.log('RESET!!!')
		this.dataInfiniteTable.nav.count = 0
		this.dataInfiniteTable.rows = [{id: '-2', loading: true}]
		this.captionSet = false
		this.endpoint = 'detail'
		this.filterUpdatedOneTime = true
		this.notFound = false
	}

	setFilter = (delivery: any, stock: any, price: any, brandsChecks: any, updateUrl?: any) => {
		const brands = [] as string[]
		brandsChecks.forEach((item: {label: string, checked: boolean, name: string, code: string}) => {
			if (item.checked) {
				brands.push(item.code)
			}
		})
		this.filtersToSend = {
			cnt: this.filters.cnt,
			delivery_min: delivery[0],
			delivery_max: delivery[1],
			stock_min: stock[0],
			stock_max: stock[1],
			price_min: price[0],
			price_max: price[1],
			brands: brands
		}

		this.filter = Object.entries(this.filtersToSend).map(item => {
			console.log(item)
			if (item[0] === 'brands') {
				return 'brands=' + brands.join(',')
			}
			else return item.join('=')
		}).join('&')
		console.log(toJS(brands))
		updateUrl && updateUrl()
		console.log(this.filter)

		this.dataInfiniteTable.nav.count = 0
		this.dataInfiniteTable.rows = [{id: '-2', loading: true}]
		this.captionSet = false
		this.endpoint = 'detail'
		this.filterUpdatedOneTime = true
		this.clearTableCache && this.clearTableCache()
		this.loadProducts(1)
	}

	setFilterDebounced = debounce(this.setFilter, 500)

	clearTableCache: undefined | (() => void) = undefined

	loadProducts = async (page: number) => {
		this.dataInfiniteTable.loading = true
		//пушим элемент прелоадер если его ещё нет
		if (!this.dataInfiniteTable.rows.find((item: any) => item.hasOwnProperty('loading'))) {
			this.dataInfiniteTable.rows.push({id: '-2', loading: true})
		}
		//если уже есть - удаляем и добавляем в конец
		else {
			let i = this.dataInfiniteTable.rows.findIndex((item: any) => item.hasOwnProperty('loading'))
			if (i !== -1) {
				this.dataInfiniteTable.rows.splice(i, 1)
				this.dataInfiniteTable.rows = [...this.dataInfiniteTable.rows, {id: '-2', loading: true}]
			}
		}

		//чистим кэш высот элементов в таблице из за манипуляций
		// (старые индексы не соответстуют новым элементам)
		this.clearTableCache && this.clearTableCache()

		return axios.get(`/api/catalog/${this.endpoint}`, {
			params: {
				article: this.article ? this.article : '',
				page: page,
				size: 20,
				filter: this.filterSet ? JSON.stringify(this.filtersToSend) : '',
				order: this.order,
				mod: this.mod
			}
		})
			.then(r => {
				this.dataInfiniteTable.nav = r.data.data.nav
				this.seo = r.data.data.seo
				if ((this.endpoint === 'cross') && (page === 1) && (r.data.data.items.length > 0)) {
					this.dataInfiniteTable.rows.push({id: '-1', captionRow: 'Возможные замены'})
					this.captionSet = true
				}
				const rows = r.data.data.items ? Object.entries(r.data.data.items).map((item: any) => item[1]) : []
				this.dataInfiniteTable.rows = [...this.dataInfiniteTable.rows, ...rows]
				console.log(toJS(this.dataInfiniteTable.rows))
				if (page < Math.ceil(this.dataInfiniteTable.nav.count / 20)) {
					this.loadProducts(page + 1)
				}
				else if (this.endpoint === 'detail') {
					this.endpoint = 'cross'
					this.loadProducts(1)
				}
				else {
					//если загрузка окончена удаляем элемент-лоадер и чистим кэш виртуального скролла
					this.dataInfiniteTable.rows = this.dataInfiniteTable.rows.filter((item: any) => item.id !== '-2')
					this.clearTableCache && this.clearTableCache()
					console.log(toJS(this.personalStore.cartTable.rows))
					// Выставляем значения по корзине
					if (this.personalStore.cartTable) {
						this.personalStore.cartTable.rows = this.dataInfiniteTable.rows.map((item:any) => {
							const result = this.personalStore.cartTable.rows.find((basketItem:any) => basketItem.uid === item.id)
							if (result){
								item.counter.value = result.count.counter.value
							}
							return item
						})
					}
					this.dataInfiniteTable.loading = false
				}
			})
			.catch(err => {
				console.log(err)
			})
	}

	getCrossesNav = (article: string) => {
		fetchSearchResultsCrosses(article, 1, 20,  this.filter, this.order, this.mod)
			.then(res => {
				console.log('ПРИЛЕТЕВШИЕ КРОССЫ')
				console.log(res)
				this.crossesNav.nav = res.data.nav
				this.crossesNav.set = true
			})
	}

	setFilterFromUrl = (filter: string) => {
		let from_cnt = filter.slice(filter.indexOf('cnt') + 'cnt'.length + 1)
		let cnt_val = from_cnt.slice(0, from_cnt.indexOf('&'))

		let from_delivery_min = filter.slice(filter.indexOf('delivery_min') + 'delivery_min'.length + 1)
		let delivery_min_val = from_delivery_min.slice(0, from_delivery_min.indexOf('&'))

		let from_delivery_max = filter.slice(filter.indexOf('delivery_max') + 'delivery_max'.length + 1)
		let delivery_max_val = from_delivery_max.slice(0, from_delivery_max.indexOf('&'))

		let from_stock_min = filter.slice(filter.indexOf('stock_min') + 'stock_min'.length + 1)
		let stock_min_val = from_stock_min.slice(0, from_stock_min.indexOf('&'))

		let from_stock_max = filter.slice(filter.indexOf('stock_max') + 'stock_max'.length + 1)
		let stock_max_val = from_stock_max.slice(0, from_stock_max.indexOf('&'))

		let from_price_min = filter.slice(filter.indexOf('price_min') + 'price_min'.length + 1)
		let price_min_val = from_price_min.slice(0, from_price_min.indexOf('&'))

		let from_price_max = filter.slice(filter.indexOf('price_max') + 'price_max'.length + 1)
		let price_max_val = from_price_max.slice(0, from_price_max.indexOf('&'))

		let from_brands = unescape(filter.slice(filter.indexOf('brands') + 'brands'.length + 1))
		console.log('from brands', from_brands)
		let brands_arr = from_brands.slice(0, from_brands.length).split(',')
		console.log(brands_arr)

		this.filtersToSend = {
			cnt: parseInt(cnt_val),
			delivery_min: parseInt(delivery_min_val),
			delivery_max: parseInt(delivery_max_val),
			stock_min: parseInt(stock_min_val),
			stock_max: parseInt(stock_max_val),
			price_min: parseFloat(price_min_val),
			price_max: parseFloat(price_max_val),
			brands: brands_arr
		}
		console.log('filter from url:')
		console.log(toJS(this.filtersToSend))

		return this.filtersToSend
	}

	getFilters = (article: string) => {
		return fetchDetailsFilters(article)
			.then(r => {
				this.filters = {
					cnt: parseInt(r.data.cnt),
					delivery_min: parseInt(r.data.delivery_min),
					delivery_max: parseInt(r.data.delivery_max),
					stock_min: parseInt(r.data.stock_min),
					stock_max: parseInt(r.data.stock_max),
					price_min: parseFloat(r.data.price_min),
					price_max: parseFloat(r.data.price_max),
					brands: r.data.brands.map((item: {name: string, code: string, cnt: string}) => {
						return {
							name: item.name,
							code: item.code,
							cnt: parseInt(item.cnt)
						}
					})
				}
				console.log('ПРИЛЕТЕВШИЙ ФИЛЬТР')
				console.log(r.data)
				this.filterSet = true
			})
			.catch(err => console.log(err))
	}

	toggleFilter = () => {
		this.filterOpen = !this.filterOpen
	}

	toggleOrder = (asc: OrderItemType, desc: OrderItemType) => {
		let i = -1

		if ((i = this.order.findIndex((item: OrderItemType) => item === asc)) !== -1) {
			this.order[i] = desc
		}
		else if ((i = this.order.findIndex((item: OrderItemType) => item === desc)) !== -1) {
			this.order.splice(i, 1)
		}
		else {
			this.order.push(asc)
		}

		this.resetTable()
		this.loadProducts(1)
	}

	sort = (field: string) => {
		switch (field) {
			case 'count':
				this.toggleOrder('stock_asc', 'stock_desc')
				break
			case 'supply':
				this.toggleOrder('delivery_asc', 'delivery_desc')
				break
			case 'price':
				this.toggleOrder('price_asc', 'price_desc')
				break
		}
	}

	get paginationSelect() {
		const options = ['5', '20']
		const perPage = options.findIndex(item => parseInt(item) === this.dataTable.nav.size)
		return {
			perPage: perPage,
			options: ['5', '20']
		}
	}

}

export default SearchResultsStore