import React, {useContext, useState} from 'react'
import {observer} from 'mobx-react'
import {StylesContext} from '../Header'
import StoreContext from '../../../stores/StoreContext'
import IconLock from '../../Svg/IconLock'
import IconArrow from '../../Svg/IconArrow'
import IconCalendar from '../../Svg/IconCalendar'
import SelectMenu from '../../SelectMenu/SelectMenu'


const Company = observer(() => {
    const selectOptions = ['ТДСТ/МСК/1245700', 'ТДСТ/МСК/1245701', 'ТДСТ/МСК/1245702']
    const stores = useContext(StoreContext)
    const styles = useContext(StylesContext)
    const [menuOpen, setMenuOpen] = useState(false)
    return (
        <div className={`${styles.menuMob__company} ${styles.company} ${menuOpen ? styles.open : ''}`}>
            <div className={styles.company__title}>{stores?.personalStore?.personal?.['contragent']?.['name']}</div>
            <div
                className={`
                    ${styles.company__total} 
                    ${parseFloat(stores?.personalStore?.contract?.['balance'] || '0') < 0 ? 'color--red' : 'color--white'}`}
                onClick={() => setMenuOpen(!menuOpen)}>
                <div className={`${styles['company__total-text']} color--red`}>
                    {stores?.personalStore?.contract?.['balance']} руб.
                </div>
                <IconArrow className={styles['company__total-icon']}/>
            </div>
            {!stores?.personalStore?.contract?.['active'] &&
                <div className={`${styles.company__status} color--yellow`}>
                    <IconLock className={styles['company__status-icon']}/>
                    <div className={styles['company__status-text']}>Временно заблокировано</div>
                </div>}
            <div className={styles.company__details}>
                <div className={styles['company__details-item']}>
                    <div className={styles['company__details-title']}>Договор</div>
                    <div className={styles.company__select}>
                        &laquo;{stores?.personalStore?.contract?.['name']}&raquo;
                    </div>
                </div>
                <div className={styles['company__details-item']}>
                    <div className={styles['company__details-delay']}>
                        {stores?.personalStore?.contract?.['isCredit'] ?
                            <>
                                <IconCalendar className={styles['company__details-icon']}/>
                                <div className={styles['company__details-text']}>{stores?.personalStore?.contract?.['creditDays']} дней отсрочки</div>
                            </>
                            :
                            <div className={styles['company__details-text']}>Договор предоплаты</div>
                        }
                    </div>
                </div>
                {stores?.personalStore?.contract?.['isCredit'] ?
                    <>
                        <div className={styles['company__details-item']}>
                            <div className={styles['company__details-title']}>Кредит</div>
                            <div className={styles['company__details-text']}>{stores?.personalStore?.contract?.['creditSum']} руб.</div>
                        </div>
                        <div className={styles['company__details-item']}>
                            <div className={styles['company__details-title']}>Остаток</div>
                            <div className={styles['company__details-text']}>{parseFloat(stores?.personalStore?.contract?.['creditSum'] || '0') - parseFloat(stores?.personalStore?.contract?.['balance'] || '0')} руб.</div>
                        </div>
                        <div className={styles['company__details-item']}>
                            <div className={`${styles['company__total-text']} ${parseInt(stores?.personalStore?.contract?.['balance'] || '') < 0 ? 'color--red' : 'color--white'}`}>Итоговая задолженность</div>
                            <div className={`${styles['company__details-text']} color--red`}>{stores?.personalStore?.contract?.['balance']} руб.</div>
                        </div>
                    </>
                    :
                        <div className={styles['company__details-item']}>
                            <div className={styles['company__details-title']}>Сумма</div>
                            <div className={styles['company__details-text']}>{stores?.personalStore?.contract?.['creditSum']} руб.</div>
                        </div>
                }
            </div>
        </div>
    )
})

export default Company
